.styled-select_new {
    max-width: 489px;
    margin: 0auto;
    width: 100%;
    margin: 0 auto;
}
.styled-select_file_new input {
    padding-bottom: 34px !important;
}

#exampleFormControlSelect1:active {
    background: #00f;
}
@media (max-width:767px) {
  .uploadFrm button.btn.yellow-btn.upload-btn {
      width: 150px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
  }
}
