@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');
*{
	margin: 0;
	padding: 0;
}


@font-face {
    font-family: 'Gotham-Bold';
    src: url('./assets/fonts/GothamBold.otf'); /* IE9 Compat Modes */
   }
   @font-face {
    font-family: 'GothamBook';
    src: url('./assets/fonts/GothamBook.otf'); /* IE9 Compat Modes */

}
@font-face {
    font-family: 'GothamUltra';
    src: url('./assets/fonts/GothamUltra.otf'); /* IE9 Compat Modes */
   }
   @font-face {
    font-family: 'GothamUltraItalic';
    src: url('./assets/fonts/GothamUltraItalic.ttf'); /* IE9 Compat Modes */
   }
   @media only screen and (min-width : 1200px) {
    .container { max-width: 1363px; }
}

.font-face-gm {
  font-family: "GothamUltra" !important;
}

.charts-gm {
    color: #0000FF;
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #0000FF;
    font-style: italic;
}

body{
	font-family:GothamBook !important;
}
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}
.Section__container___3YYTG .Form__formContainer___1GA3x::before {
    content: '';
    background: url(./assets/images/old_logo.png);
    height: 50px;
    z-index: 9999;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    background-position: center;
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
    margin-top: -1em;
}
.headerpanel{
    display: flex;
    padding-top:0.5rem;
    padding-bottom:0.5rem;
        padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.left-icon {
    width: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.left-icon > div {
	width: 28%;
text-align: left;
/* transform: scale(.8); */
}
.left-icon button.btn.btn-primary {
    padding: 4px 8px;
    font-size: 12px;
}

.right-icon {
    width: 110px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.right-icon img {
	height: 22px;
	width: auto !important;
}
.right-icon a {
    width: 50%;
    text-align: right;
}

.mid-logo {
    width: calc(100% - 262px);
    text-align: center;
}
.mid-logo img {
   max-height: 30px !important;
}


.navbarpanel.navbar{
	background: #333333 !important;
	padding: .3rem 1rem;
}
.navbarpanel.navbar-expand-sm .navbar-nav .nav-link {
padding-left: 0.5rem;
    padding-right: 3.2rem;
    color: rgba(255,255,255,0.7);
    font-family:GothamBook !important;
    font-size:14px;
    font-weight: 300;
}
.navbarpanel.navbar-expand-sm .navbar-nav .nav-link:hover{
    color: rgba(255,255,255,0.7);
}

/*--------- switch ---------*/
.material-switch > input[type="checkbox"] {
    display: none;
}

.material-switch > label {
    cursor: pointer;
    height: 0px;
    position: relative;
    width: 40px;
}

.material-switch > label::before {
    background: #888888;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.9);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position:absolute;
    opacity: 0.8;
    transition: all 0.4s ease-in-out;
    width: 40px;
}
.material-switch > label::after {
    background: rgb(255, 255, 255) !important;
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.9);
    content: '';
        height: 21px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 21px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
}
.material-switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 20px;
}
.btn-primary {
    color: #fff;
    background-color: #0000ff;
    border-color: #007bff;
}


.midCharButton{display: flex; flex-direction:row; justify-content: space-around; max-width: 800px; width: 100%; margin:0 auto; margin-top:50px; }
.midCharButton h2{font-size: 26px; font-weight:900; color:#0000FF;}
.uploadbtn { margin: 10px;width: 253px;  height:217px; display: flex; align-items: center; justify-content: center; padding: 7px; border:solid 1px #ccc; }
.upbg1{background:url(./assets/images/upload-img1.jpg) no-repeat center center; background-size: 94%; }
.upbg2{background:url(./assets/images/upload-img2.jpg) no-repeat center center; background-size: 94%; }
.midChatSectonBtn {
    display: flex;
    margin-top: 1em;
    justify-content: center;
}

/* ----------old --css */

body{
  /*font-family: 'Roboto Condensed', sans-serif;*/
  font-family:GothamBook !important;
}

.MuiDialogTitle-root{
  background-color: #162575;
  color: #fff;
}
select.form-control {
    -webkit-appearance: none;
    -moz-appearance: inherit;
    background: transparent;
    background-image: url(./assets/images/arrow.png);
    background-position-x: 100%;
    border: 1px solid #dfdfdf;
    margin-right: 2rem;
    border-left: 1px solid #000;
    background-repeat: no-repeat;
    background-size: 40px 32px;
}
.form-control {
  /*  color: #fff !important;*/
    border-color: #0000FF !important;
    border-radius: 0px !important;
}
.styled-select .form-control{
    padding: 6px 10px !important;
    height: 43px !important;
    cursor: pointer;
    background:#eee !important;
    border: solid 1px #0f0fff !important;
   color: #808080 !important;
 }
.css-g8u311-control {
    background: #eee !important;
    border: solid 1px #0f0fff !important;
    min-height: 45px;
    }
.uploadbtnwrap{    display: flex;  align-items: flex-end; }
.uploadbtnwrap .css-e56m7-control {border-color: hsl(45deg 100% 50%);}
.uploadbtnwrap .css-e56m7-control:focus{border:solid 1px #ffc000 !important;}
.uploadbtnwrap .form-control:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255,192,0,.25) !important;
}
.lft-selectWraper{ margin-right:0; }
.css-e56m7-control { border-color: hsl(45deg 100% 50%); }

.downloadicon {
        fill: #57e25e;
    height: 34px;
    width: auto;
    padding-top:4px; background: #fff;
}
button.btn.download-btn {
    padding: 0;
}
.hide_show_chart .fa.fa-check-circle{background:#fff;     font-size: 37px;}
/*.save-as-row.saveasrht { position: absolute;  right: -32px; bottom: -16px;}*/
.save-as-row.saveasrht {
    position: absolute;
    right: -12px;
    bottom: -12px;
}

select.form-control {
    color: #fff !important;
    border-color: #0000FF !important;
    border-radius: 0px !important;
}
.btn.focus, .btn:focus{
    box-shadow: 0 0 0 0.2rem rgb(255, 241, 207);
}
.yellow-btn{
    background-color: #fff1cf;
    border-color: #ffc000;
    border-radius: 0;
    padding: 4px 10px;
    width: 100%;
    color: #777;

}
.yellow-btn:hover, .yellow-btn:focus, .yellow-btn:active{
  background-color: #000000;
  border-color: #0000FF;
  color: #fff;
}

.form-control{
    padding: 4px 10px !important;
    height: 33px !important;
    cursor: pointer;
}

.form-control:focus {
    background-color: #0000FF;
    border-color: #0000FF; color: #fff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255,192,0,.25) !important;
}

 .dropdown-toggle-split{
    padding-left: 10px!important;
    padding-right: 10px !important;
 }
.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active{
    color: #212529 !important;
    background-color: transparent!important;
    border-color: #ffc107 !important;
}

.logo img{
    max-height: 70px;
}
.menu-active .content-toogle .fa-bars:before{
    content: "\f00d";
}

#wrapper {
  padding-left: 0;
  transition: all 0.4s ease 0s;
/*  padding-top: 70px;*/
}
.logo-wraper{
  width: calc(100% - 50px);
    text-align: center;
}
#sidebar-wrapper {
  margin-left: -250px;
  left: 0;
  width: 250px;
  background: #fff;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  margin-top: 80px;
  z-index: 1000;
  transition: all 0.4s ease 0s;
}
#wrapper.menu-active  #page-content-wrapper{
/*  position: relative;*/
  left: 250px;
  padding-left: 250px;
  transition: all 0.4s ease 0s;
}

#wrapper.menu-active #sidebar-wrapper {
  left: 250px;
  width: 250px;
  transition: all 0.4s ease 0s;
}

#page-content-wrapper {
  width: 100%;
  transition: all 0.4s ease 0s;
  padding-top: 60px;
}



.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-nav li {
  line-height: 40px;
  text-indent: 20px;
  width: 100%;
}

.sidebar-nav li a {
  color:#ffc000;
  display: block;
  text-decoration: none;
}
.sidebar-nav li a span{
}
.sidebar-nav li a span:before {
  position: absolute;
  left: 0;
  color: #41484c;
  text-align: center;
  width: 20px;
  line-height: 18px;
}

.sidebar-nav li a:hover,
.sidebar-nav li.menu-active {
    color: #777777;
  background: rgba(255,255,255,0.2);
  text-decoration: none;
}

.sidebar-nav li a:menu-active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  line-height: 60px;
  font-size: 18px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}



.content-header {
    height: 80px;
    line-height: 80px;
    z-index: 999;
    position: fixed;
    width: 100%;
    background: #fff;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid #e9ecef;
}
.logo-wraper{
   width: calc(100% - 50px);
    text-align: center;
}


.content-toogle {
    margin: 0;
    margin-left: 20px;
    line-height: 70px;
    display: inline-block;
    font-size: 30px;
}
ul.sidebar-nav.nav{
      margin-top: 20px;
}
#menu-toggle {
    text-decoration: none;
    font-size: 28px;
    margin-right: 20px;
    color:#ffc000;
    width: 50px;
}

.btn-menu {
  color: #000;
}

.inset {
  padding: 20px;
}
.upl-file-row{
  margin-top: 1em;
  margin-bottom: 1em;
}
.upl-file-row .row {
  margin: 1em auto;
    justify-content: left;
}
.left-side-box{
  width: 100%;
  height: calc(100vh - 130px);
  min-height: 350px;
   border: 1px solid #9999;
       overflow: auto;
  /* overflow: auto; */
  /* max-height: 400px; */
      display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.right-side-box{
  width: 100%;
  height: calc(100vh - 130px);
    min-height: 350px;
  border: 1px solid #9999;
  }
.save-as-row .row {
    margin: 0 auto;
    justify-content: center;
    margin-top: 2em;
}
.btn-file {
  position: relative;
  overflow: hidden;
  background-color: #0000FF !important;
  border-color: #0000FF !important;
  color: #fff !important;
  border-radius: 0px !important;
  padding: 4px 10px !important;
  width: 100%;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
  cursor: pointer;
}
.upl-file-row.upl-file-sec {
    margin-top: 4em;
}
.upload-btn {
    margin-top: 1em;
}


 p.help.is-danger {
  font-weight: bold;
  color: #ff0000;
  font-size: 12px;
}

ul, .right-sidebar {
  list-style-type: none; position: relative;
}

.right-sidebar {
  margin: 0;
  padding: 0;
}

.right-sidebar .caret {
  cursor: pointer;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.right-sidebar .caret::before {
  content: "\f067";
   font-family: FontAwesome;
  color: black;
  display: inline-block;
  margin-right: 6px;
      font-size: 12px;
    font-weight: normal;
}

.right-sidebar .caret-down::before {
  content: "\f068";
   font-family: FontAwesome;
  color: black;
  display: inline-block;
  margin-right: 6px;
      font-size: 12px;
    font-weight: normal;
}

.right-sidebar .nested {
  display: none;

}

.right-sidebar .active {
  display: block;
}
.right-sidebar ul{
    margin:0;
    padding: 10px 0;
}
.right-sidebar > li {
     border-bottom: 1px solid #DADADA;
    padding: 6px 0;
    padding-left: 14px;
}
.right-sidebar table {
    padding-left: 10px;
    padding-right: 10px;
    background-color: #fff;
    width: 100%;
/*    -webkit-box-shadow: 0px 6px 5px -7px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 6px 5px -7px rgba(0,0,0,0.75);
box-shadow: 0px 6px 5px -7px rgba(0,0,0,0.75);*/
}

.right-sidebar table tr td select {

    width: 100%;
    margin: 0px;
    resize: vertical;
    padding: 4px 0px 4px 4px;
    border: none;
    background-color: transparent;
    border: 1px inset transparent!important;
}
 .right-sidebar select:hover{
     background-color: rgba(0,0,0,0.2);
 }

.right-sidebar table tr td input {
    width: 100%;
    margin: 0px;
    padding: 4px 0px 4px 4px;
    border: none;
}
.right-sidebar table tr td input:hover {
   background-color: rgba(0,0,0,0.2);
}
.right-sidebar table tr td {
    display: table-cell;
     padding-top: 6px;
    padding-bottom: 6px;
}

.right-sidebar span.caret.caret-down {
    font-size: 20px;
    color: #e25f5f;
    font-weight: bold;
}

.right-sidebar table tr td .circle{
    background-color: rgb(82, 34, 34);
    vertical-align: middle;
    outline: none;
    border: 2px solid #dcdada;
    border-radius: 15px;
    width: 25px;
    height: 25px;
    }


.right-sidebar .nested.active  span.caret.caret-down {
    font-size: 16px;
    }
.chart-img{
    display: block;
    text-align: center;
    position: relative;
    margin: auto;
}
#menu-toggle {  color: #00f; }
.sidebar-nav li a { color: #0000FF; }
.yellow-btn { background-color: #0000FF;
    border-color: #0000FF; color: #fff; }
.btn-info {
    color: #fff;
    background-color: #0000FF;
    border-color: #17A2B8;
}

.btn-lg, .btn-group-lg > .btn {
    padding: 0.5rem 2rem;
    font-size:1.25rem;
    line-height:3.5;
    border-radius: 0.3rem;
}
.hide_show_chart {
    font-size: 28px;
    position: absolute;
    right: 2px;
    top: -12px;
    cursor: pointer;
    z-index: 2;
}
.hide_show_chart i.fa.fa-times-circle {
    font-size: 37px;
     background: #fff;
}
/*.hide_show_chart{  font-size: 28px; position: absolute; right: -12px; top: -12px; cursor: pointer; }*/
/*.page-content-row{ margin: 0 4.7em; }*/
.page-content-row{ margin: 0 0em; }
.boxWrapper{position: relative; /*border:solid 1px #ccc;*/ margin-top:1em;/* padding:0 0 2em;*/}
.lftHandCursor {
     font-size: 28px;
    position: absolute;
    left: 0px;
    top: -10px;
    cursor: pointer;
    background: transparent;
    z-index: 9;
}

/*.lftHandCursor{ font-size: 28px; position: absolute; left: -37px; top: -16px; cursor: pointer; background: #fff;}*/
.lftHandCursor svg{    width: 44px;
    background: #fff;
    height: auto;}
.lftHandCursor img {
  width: 30px;
  background: #fff;
  height: auto;
}
.leftHelpBottom{ }
.leftHelpBottom {
    font-size: 28px;
    position: absolute;
    left: 0px;
    bottom: -16px;
    cursor: pointer;
    background: #fff;
}
/*.leftHelpBottom{ font-size: 28px; position: absolute; left: -19px; bottom: -16px; cursor: pointer; background: #fff;}
*/.leftHelpBottom svg{width:30px;  height:40px}
/*.upl-file-row {
    margin-top:  0.2em;
    margin-bottom: 5em;
}*/
.custom-model-popup .modal-card{
      width: 800px;
}

.modal-card-head{
      padding: 10px 20px;
}
.modal-card-head p{
      margin: 0;
      width: fit-content;
}

.custom-model-popup .modal-card-body {
      overflow: unset;
      height: 500px;
      padding-top: 0;
  }

.custom-model-popup .container{
      padding-left: 0;
      padding-right: 0;
}

.container-full{
    padding-left: 15px;
    padding-right: 15px;
}
.circle div{right:0 !important;}
.circle33 {
  width: 25px;
  height:25px;
}
.backcolor-circle div{
right:0 !important;
}
.backcolor-circle .sketch-picker{
    margin-right: -6em;
}
/*.sketch-picker { margin-right: 4em; }*/
.stock-circle, .fill-circle, .backcolor-circle{
    position: relative;
}
select#exampleFormControlSelect1{
  background: transparent !important;
    height: 36px !important;
    background-color: hsl(0,0%,100%) !important;
    border-color: hsl(0,0%,80%) !important;
    -webkit-appearance: auto;
    -webkit-border-radius: 0;
    border-radius: 0;
    min-width: 80px;
}

/* scroll css start*/
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.textBoxFile{clear:both; padding:10px; max-width: 500px;
    width: 100%;
    margin: 0 auto; }
.textFileName{border:solid 1px #00f }
.textFileName ul{margin:0; padding:0; list-style-type: none; border-bottom:solid 1px #ccc;  height:240px; overflow: auto; }
.textFileName ul li{padding:10px; border-bottom:solid 1px #ccc; }
.textBoxFile h2{font-size: 22px; font-weight: bold; clear: #00f; margin-top:1em; }

.durationTime{display: flex; font-size: 16px; padding:10px 0; justify-content: flex-end;     align-items: baseline;}
.durationTime h3{font-size: 17px; padding-right:10px;}
.relativePosition select{border:Solid 1px #00f; padding:4px;}
 #column-timeStamp { flex-direction: row-reverse; color: #000; font-weight:600;  font-size: 16px;     justify-content: space-between;}
 #column-label {  font-size: 16px; color: rgba(0,0,255,1); font-weight:600; justify-content: space-between; max-width: 300px; width: 100%; }
 #column-timeStamp span,  #column-label span {

    color: rgba(0,0,255,1); font-weight: 600;
}
.rdt_TableHeadRow {
    border-bottom: solid 1px #00f;
}
#column-timeStamp {
    max-width:150px;
    color: rgba(0,0,255,1);
    /*border-left: solid 1px #00f;*/
    padding-left: 10px;
    width: 100%;
}
.rdt_TableHead {
    border-bottom: solid 1px #00f;
}

/*scroll css end */
/*#thecanvas{

height: calc(100vh - 160px) !important;
}
*/
.reports-dtl .d-block {
      font-size: 17px;
    margin-bottom: 4px;
    max-width: 400px;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
      text-transform: capitalize;

}
 .reports-dtl .user-save{
  background-color: #c7ebfe;
  border: 1px solid #c7ebfe;

 }
 .reports-dtl .default-report{
    background-color: #ffffff;
    border: 1px solid #c7ebfe
 }
.reports-dtl .del-report{
    background-color: #ffcaca;
    border: 1px solid #ffcaca
  }
.custom-filter  .css-1hb7zxy-IndicatorsContainer svg{
  display: none !important;
}
.custom-filter .styled-select.arrows{
     float: left;
    width: 100%;
}
.custom-filter .css-1hb7zxy-IndicatorsContainer {
    margin-right: 13px;
}
.sort_asse{
  float: left;
    width: 30px;
    position: absolute;
    right: 8px;
    top: -5px;
    font-size: 20px;
    color: #cccccc;
}
.sort_desc{
    float: left;
    width: 30px;
    position: absolute;
    right: 8px;
    bottom: -7px;
    font-size: 20px;
    color: #cccccc;
}
.custom-filter .active-filter, .custom-filter .active-filter:hover, .custom-filter .active-filter:focus, .custom-filter .active-filter:active{
    color: #000 !important;
}
.language-btn .btn-primary {
    background: #00f;
}
.language-btn .dropdown ul{
     margin: 0;
    border: solid 1px #00f;
    background: #fff; padding:0;
    position:absolute;
    top: 62px;
}
.language-btn .dropdown ul li{line-height:44px}
.language-btn .dropdown ul li .btn{
display:block;
width: 100px;
padding: 0 0 0 20px;
background:#eee;
border-bottom:solid 1px #00f;
color:#00f;
}

.left-icon ul.dropdown {
    position: absolute;
    z-index: 9999999;
    background: #fff;
    border: solid 1px #0101FF;
}


/*.custom-filter  .css-1hb7zxy-IndicatorsContainer{
    width: 38px;
}
.custom-filter .css-1hb7zxy-IndicatorsContainer:after {
     font-family: FontAwesome;
    content: "\f107";
    display: inline-block;
    padding-right: 2px;
    vertical-align: middle;
    position: relative;
    top: 10px;
    left: -7px;
    font-size: 20px;
        color: #cccccc;
}
.custom-filter .css-1hb7zxy-IndicatorsContainer:before {
     font-family: FontAwesome;
    content: "\f106";
    display: block;
    vertical-align: middle;
    padding: initial;
    top: 0;
    margin-top: -4px;
    position: absolute;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
    height: 20px;
        color: #cccccc;
}*/
.css-tlfecz-indicatorContainer{
  display: none !important;
}
.css-1wy0on6{
  display: none !important;
}
.css-1okebmr-indicatorSeparator{
  display: none !important;
  }


select:focus {
  background-color: yellow;
}


.view_graph_btn {
    margin: 2em auto;
    text-align: center;
}

 .viewgraph-btn {
    width: 150px;
}

.unsubscribe_btn .btn{
    margin: 2em auto;
    text-align: center;
       width: 150px;
}

.circle > div:nth-child(2){
    margin-top: -31px;
    padding-right: 2em;
}

 .circle33 > div:nth-child(2) {
    margin-top: -31px;
    padding-right: 8em;
}

.saveDel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 30px 0;
}
.saveDel img {
    width: 30px;
}

.circle > div:nth-child(2) {
    margin-top: -64px;
    padding-right: 2em;
}
.topRightLogout {
    display: flex;
}
.topRightLogout a {
     padding-left: 0;
      padding-right: 0;
      width: 57px;
      text-align: right;
}
.topRightLogout img {
     width: 59%;
   }
   .prof {
    width: 50% !important;
}
.col-md-1.col-lg-1.text-allign-r {
    width: 26px; padding: 0;
}

.toolTipSwitch .react-switch {
  position: relative;
  top: 9px;
  left: 6px;
}
.toolTipSwitch {
    transform: scale(0.7);
}
 .react-tabs {
    -webkit-tap-highlight-color: transparent;
    margin-top: 44px;
}
.content-header {
    height: 100px;
    }
 #page-content-wrapper {
    width: 100%;
    transition: all 0.4s ease 0s;
    padding-top: 75px;
}
.logo {
	max-width: 370px;
 width: 100%;
 margin: 0 auto;
}
.logo_tag {
	font-size: 11px;
margin-top: 1px;
text-align: right;
padding-right: 13px;
color: #00f;
margin-bottom: 11px;
}

@media (max-width:767px) {
.logo img{
  width: 100%;
    height: auto;
}
#wrapper {
  padding-left: 0;
}

#sidebar-wrapper {
  left: 0;
}

#wrapper.menu-active {
  position: relative;
  left: 0px;
}

#wrapper.menu-active #sidebar-wrapper {
  left: 250px;
  width: 100%;
  padding-left: 0;
  transition: all 0.4s ease 0s;
}

#menu-toggle {
  display: inline-block;
}

.inset {
  padding: 15px;
}
.form-control{
    margin-bottom:1em;
}
.right-side-box {
    margin-top: 1em;
    }
}

.navbarpanel ul {
    margin: 0;
    display: flex;
    padding-left: 0;
}

.navbarpanel {
    /*background: #333333 !important;*/
    background: rgba(0,0,255,1) !important;
    padding: .3rem 1rem;
}
.switch-btn {
	transform: scale(.8);
	margin-top: 9px;
}

.returnBtnImg img {
    width: 50px;
    margin: 0 auto;
    text-align: center;
}
.returnBtnImg {
    text-align: center;
}
.pvtUi {
    color: #2A3F5F;
    font-family: Verdana;
    border-collapse: collapse;
    text-align: center;
    margin: 0 auto;
}

.firstBtn-one span,  .firstBtn-two span, .firstBtn-three span{
    display: block;
    color: #4572C3;
    font-size: 18px;
    text-align: center;
    padding-left: 11px;
    padding-top: 4px;
	}

  .page-content.inset-profile {
    max-width: 470px;
    width: 100%;
    background: #F6F3FF;
    border: solid 1px #0000FF;
    margin-top: 6em;
    margin: 6em auto;
}
.profileLoginBox {
     padding: 54px;
    padding-bottom: 36px;
}
.mailUser input {
    padding: 26px 20px !important;
    background: #fff !important;
    color: #333 !important;
}
.unsubscribe_btn button {
    margin: 18px 0 !important;
    padding: 10px;
    width: 100% !important;
    font-size: 24px;
}
.themeCodePick  {
      display: flex;
    justify-content: flex-end;
    border-top: solid 1px #ccc;
    padding-top: 19px;
    margin-top: 8px;
  }
  .themeCodePick h3 {
    font-size: 20px;
    margin-right: 10px;
}
.page-content-wrapper-profile{
    width: 100%;
    transition: all 0.4s ease 0s;
     padding-top: 15px !important;
}
.navbarpanel img.prof-profile {
    width: 2% !important;
}


/*new home page css */

.mainscreenbody{
	background:url(./assets/images/tran-bg-body.png) right top no-repeat;
  background-position:fixed; background-size: 100%;
  overflow:hidden;
  padding-bottom:0;

  }
.twocolbox{display: flex; flex-direction:row;}
.oneLeftCol{flex:0 0 50%;  width:50%;}
.oneRhtCol{flex:0 0 50%; width:50%; }
.logoPanel img{width: 90%; margin-left:3%; animation:pulse 2s linear 1; }
.logoPanel{margin-top:49%;}
.contentWrapper{margin-top:6%;}
.contentWrapper{margin-left:3%; max-width:500px; width:100%;}
.contentWrapper p{font-size:1.2em; color:#666; font-weight: 500;}
.graphicBox{margin-top:17%; position: relative;}
/*.topButtonSignIn{margin-top:3%; text-align: right; margin-right:100px;}*/
.graphicBox img { width: 79%; position: relative; left:8%; top:0; animation: run 2s infinite; }
.error-message{ margin-left: 67px; color: #FF0000;}
/*.signinbtn{ background: #2122FF; border: 0; outline:0; color: #fff; padding: 8px 46px 9px;
            border-radius: 35px; text-transform: uppercase; font-weight: bold;
            letter-spacing: 1px; font-size: 14px;}
.signinbtn:hover{ box-shadow: 0 0 12px #ffffff; transition: all 0.7s; }*/
ul li.react-tabs__tab { 
    color: #abaaaa !important;
}
.react-tabs__tab--selected { 
    color: #fff !important;
}
.react-tabs__tab--selected, .react-tabs__tab:hover, .react-tabs__tab:focus, .react-tabs__tab:active { 
    color: #fff !important;
}

@keyframes pulse{
    0%{
      transform:translate(0px, 200px);
      opacity: 0;
      transform: scale(0 1.5);
      }
      100%{
      transform:translate(0px, 0px);
      opacity:1;
      }
    }
  @keyframes run{
      0%{
        transform:translate(0px, -20px);
        opacity:.3;
        transform: scale(.99);
        transition: all 2s;
        }
        100%{
        transform:translate(0px, 0px);
        transition: all 2s;
        opacity:1;
        }
      }
      .nav-bottom{font-family:GothamBook !important; display: flex; margin-left: 3%; margin-top: 16%;}
      .nav-bottom a{color:#1111BE; font-size:24px; padding-right:40px; text-transform: uppercase; font-weight: 600;}
      .nav-bottom a:hover{text-decoration:none; color:#F94D1A; transition: all 2s;}
      .prescenlogo { position: absolute; top: 43%; left: 39%; animation:run 2s infinite; display: none;  }
      .prescenlogo img{ width:76%; }


      @media only screen and (max-width: 1600px){
         .prescenlogo img { width: 60%; }
        .prescenlogo { left: 38%; }
      }
      @media only screen and (max-width:1450px) {
		.nav-bottom {
		    margin-top: 13%;
		}
		.nav-bottom{ margin-top: 10%;}
		}
     @media only screen and (max-width: 1250px){
        .prescenlogo { left: 37%; }
        .contentWrapper p { padding-right: 5em; font-size: 15px; }
        .nav-bottom a {  font-size: 18px; }
        .prescenlogo img {  width: 50%; }
        .graphicBox { margin-top: 16%; }
        }
      @media only screen and (max-width:990px) {
        .prescenlogo img { width: 38%; }
      }



@media only screen and (max-width:767px) {
.content-header {
    height: 74px;
}
.logo_tag {
    font-size: 12px;
    margin-top: -26px;
    text-align: right;
    padding-right: 3px;
    color: #00f;
    line-height: normal;
}
.react-tabs {
    -webkit-tap-highlight-color: transparent;
    margin-top: 6px;
}
.upl-file-row .row {
    text-align: center;
}
.midCharButton {
    display: flex;
    flex-direction: column;
    }
 .uploadbtn{margin:0 auto;}
 .logo_tag {
    font-size: 8px;
    margin-top: 1px;
    }
}


/* mob css 7 may */
.mob-logoPanel, .mob-backimg, .mob-singin{display: none;}

@media only screen and (max-width:767px) {
    .oneRhtCol, .logoPanel{display: none;}
  .mob-logoPanel, .mob-backimg, .mob-singin{display: block;}
  .mob-backimg , .mob-backimg img, .mob-logoPanel img{ width: 100%; }
  .mob-logoPanel img{max-width: 891px; width:100%; padding:10px 20px;}
.mainscreenbody { background:transparent; }
.contentWrapper{margin:0; max-width:100%; width: 100%; padding:0 15px;}
.contentWrapper p{width:100%; margin:0; padding:0;}
 .oneLeftCol { flex: 0 0 100%; width:100%; }
 .nav-bottom {
    margin-top: 10%;
    display: flex;
    justify-content: center;
    margin-left: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
}
 .nav-bottom {position: fixed; background-color: #fff; box-shadow: 0 0 10px #999;}
 .nav-bottom a {  margin: 0;  padding:0 20px  20px; padding-bottom: 6px;  padding-top: 6px; }
 .topButtonSignIn {margin-top:0; text-align: right; margin-right: 20px; }
 .mob-backimg { margin-top: -28px; }
 .signinbtn { padding:10px 23px 10px; margin-top:6px; }
 .contentWrapper { padding-bottom: 5em; }

 /* css for mobile header 11 Oct 2021 */
 .top-header .left-logo {
  text-align: left !important;
  width: 100% !important;
}
.top-header .left-logo img {
  max-width: 166px !important;
}
.top-header .nav-right li { 
  width: 40px !important; 
}
.top-header .nav-right li img {
  height: 22px !important;
}
.react-tabs__tab { 
  padding: 0px 5px !important; 
font: bold 10px/23px Gotham-Bold !important;
}
.modal-card-title { 
  font-size: 0.8rem !important;
}
.popup-content .modal.is-active {
  padding: 0 2em !important;
}
.col-md-11.col-lg-11.pl-0.custom-filter {
  width: 88% !important;
}
ul.nav-right .dropdown button {
  line-height: 5px !important;
}
body .react-tabs__tab { 
  line-height: 31px !important; 
}
.react-switch {   transform: scale(0.7) !important;}
.copyright p { 
  font-size: 12px !important;
}

.list-view-row h4 { 
  font: normal normal bold 25px/33px Gotham-Bold !important;
}

.list-view-row .box-title p { 
  font: normal normal normal 18px/28px GothamBook !important;
}

.list-view-row h5 { 
  font: normal normal normal 35px/46px GothamBook !important;
}
.midprocesscont { 
  height: 200px !important;
}

/* css for home page moible responsive */ 
.midprocesscont {  height: auto !important; }
.d-flex { display: flex !important; flex-direction: column; }
.hero-img {  justify-content: center; }
.hero {  height: 100% !important;  padding-bottom: 20p; }
 
.hero .hero-img img { width: 100% !important; max-width: 100% !important; }
.heroLeftCol {  padding-top: 0; flex-direction: column; }
.heroLeftCol .col-icon {  width: 100% !important; }
.col-icon ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.col-icon ul li {
    padding: 0;
    margin: 0;
    margin-bottom: 01em !important;
}
.heroLeftCol .col-text {  width: 100% !important; }
.list-view-row h5 { 
    font-size: 30px !important;
}
.list-view-row h4 {
    font: normal normal bold 20px/24px Gotham-Bold !important;
}
.presto-ar-left {
    padding-top:0 !important;
    text-align: center;
}
.presto-ar-left img { transform: rotate(90deg); }
 
.heroLeftCol {  padding-top: 0 !important; }
.top-header .left-logo { padding-top: 0px !important; width: 50% !important; }
.col-icon ul li img { width: 50%; }
.presto-aiml .dat-img {  height: auto !important; }
.dat-img img{max-width: 100% !important;}
.top-header .right-box { 
    position: relative !important; 
    justify-content: center !important; width: 100% !important;
}
.top-wrap.d-flex.align-items-center {
    flex-direction: row !important; justify-content: space-around;
}
 

}

@media only screen and (max-width: 500px){
.signinbtn {  font-size: 12px; }
 .mob-backimg { margin-top: -18px; }
.mob-logoPanel img {  padding-bottom:4px; }
}

@media only screen and (max-width:400px){
.signinbtn { padding: 3px 11px 3px; margin-top: 2px; font-size: 10px; }
}
