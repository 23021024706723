.modal {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 40;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.86);
}

.modal-content,
.modal-card {
  margin: 0 20px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;
  width: 100%;
}

.modal-close {
  background: none;
  height: 40px;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 40px;
}

.modal-card {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  overflow: hidden;
  -ms-overflow-y: visible;
}

.modal-card-head,
.modal-card-foot {
  align-items: center;
  background-color: whitesmoke;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
}

.modal-card-head {
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-card-title {
  color: #363636;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.modal-card-foot {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid #dbdbdb;
}

.modal-card-foot .button:not(:last-child) {
  margin-right: 0.5em;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: white;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 20px;
}

.modal-card-body .deleteBtn {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: transparent;
  border-radius: 18px;
  border: none !important;
}
.modal-card-head {
  padding: 10px 20px;
}
.modal-card-head button.deleteBtn {
  border: none;
  background: transparent;
}
.popup-content {
  background: transparent !important;
  border: 0 !important;
}
.popup-overlay {
  z-index: 9999 !important;
}

@media screen and (min-width: 769px), print {
  .modal-content,
  .modal-card {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 800px;
  }
}

p.help.is-danger {
  font-weight: bold;
  color: #ff0000;
  font-size: 12px;
  margin-left: 10px;
  margin-top: 13px;
}

.modelBodyOverlay {
  position: fixed;
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
}
.header-main {
  position: relative;
  z-index: 99 !important;
  border-radius: 4px;
  max-width: 450px;
  width: 100%;
  /* height:600px; */
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 2.5em 2em 1.5em;
  background: #fff;
  -webkit-box-shadow: -1px 4px 28px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: -1px 4px 28px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 4px 28px 0px rgb(0 0 0 / 75%);
}
.main-icon {
  text-align: center;
}
.main-icon span {
  border: solid 1px #ccc;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background: rgb(136, 136, 136);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.main-icon span.fa {
  font-size: 50px;
  color: rgb(8, 103, 192);
  margin-bottom: 20px;
  overflow: hidden;
}
.main-icon span.fa img {
  width: 100px;
  height: auto;
}
.icon1 {
  margin: 0 0 1em;
  padding: 0.8em 1em;
  background: rgba(255, 255, 255, 0.94);
  border: solid 1px #ddd;
}
.main-icon h3 {
  color: #0503c3;
  padding-bottom: 20px;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600;
}
.icon1 span.fa {
  color: #222;
  width: 22px;
}
.header-left-bottom input {
  outline: none;
  font-size: 15px;
  color: #222;
  border: none;
  width: 90%;
  display: inline-block;
  background: transparent;
  letter-spacing: 1px;
}
.login-check {
  position: relative;
}
.checkbox {
  position: relative;
  display: block;
  padding-left: 30px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-size: 14px;
  color: #222;
}
input[type='checkbox' i] {
  display: none;
}
.checkbox i {
  position: absolute;
  top: 0px;
  left: 1%;
  text-align: center;
  display: block;
  width: 19px;
  height: 17px;
  outline: none;
  background: rgb(255, 255, 255);
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -o-border-radius: 0px;
  cursor: pointer;
  border: solid 1px #333;
}
.checkbox input:checked + i:after {
  opacity: 1;
}
.checkbox input + i:after {
  content: url(https://demo.w3layouts.com/demos_new/template_demo/11-02-2019/slide_login_form-demo_Free/227883815/web/images/tick.png);
  top: -4px;
  left: 1px;
  width: 15px;
  height: 15px;
}
.checkbox input + i:after {
  position: absolute;
  opacity: 0;
  transition: opacity 0.1s;
  -o-transition: opacity 0.1s;
  -ms-transition: opacity 0.1s;
  -moz-transition: opacity 0.1s;
  -webkit-transition: opacity 0.1s;
}
.bottom {
  margin: 3em 0 0;
}
.header-left-bottom button.btn {
  background: #0503c3;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  padding: 0.8em 2em;
  letter-spacing: 1px;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: none;
  width: 100%;
}
.header-left-bottom p {
  font-size: 17px;
  color: #000;
  display: inline-block;
  width: 50%;
  margin: 20px 0 0;
  letter-spacing: 1px;
  float: left;
}
.header-left-bottom p a {
  font-size: 11px;
  color: #070707;
  text-transform: uppercase;
}
.header-left-bottom p.right {
  text-align: right;
}
.clear {
  clear: both;
}
.social {
  margin: 2em 0 0;
  display: flex;
  justify-content: center;
}
.social ul li {
  display: inline-block;
  margin: 0 5px;
  font-size: 15px;
  color: #222;
  letter-spacing: 1px;
  text-transform: capitalize;
}
.social ul li a {
  background: rgba(255, 255, 255, 0.22);
  width: 35px;
  height: 35px;
  line-height: 35px;
  display: block;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: #fff;
}
.social a.twitter {
  background: #1da1f2;
}
.social a.google {
  background: #dd4b39;
}
.social a.facebook {
  background: #3b5998;
}
.logomain img {
  max-width: 300px;
  width: 100%;
  margin-bottom: 1em;
}
.links {
  padding-top: 6px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}
.deleteBtn,
.deleteBtn:focus {
  border: 0;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  right: 6px;
  top: 4px;
  outline: 0;
}
