.modal {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 40;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.86);
}

.modal-content,
.modal-card {
  margin: 0 20px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;
  width: 100%;
}



.modal-close {
  background: none;
  height: 40px;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 40px;
}

.modal-card {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  overflow: hidden;
  -ms-overflow-y: visible;
}

.modal-card-head,
.modal-card-foot {
  align-items: center;
  background-color: whitesmoke;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 20px;
  position: relative;
}

.modal-card-head {
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-card-title {
  color: #363636;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.modal-card-foot {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid #dbdbdb;
}

.modal-card-foot .button:not(:last-child) {
  margin-right: 0.5em;
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: white;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 20px;
}

.modal-card-body .deleteBtn{
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background:transparent;
  border-radius: 18px;
  border: none !important;
}
.modal-card-head{
      padding: 10px 20px;
}
.modal-card-head button.deleteBtn{
      border: none;
    background: transparent;
}
.popup-content {
    background: transparent !important;
    border: 0 !important;
}
.popup-overlay {
    z-index: 9999 !important;
}

select.form-control {
    background-image: url(../../assets/images/arrow-new.png) !important;
    background-size: 22%;
    background-position: right center;
}
canvas.react-pdf__Page__canvas {
    width: 100% !important;
}
.uploadFrm{display: flex;  justify-content: start !important; align-items: center;}
.helpupload {     text-align: left; align-items: center; padding: 10px 6em 0 2.2em; }
.helpupload{width:40px !important;}
.helpupload .leftHelpBottom{left: 49px; top: 9px;}


.downloadmodalbtn {
    text-align: center;
    padding: 0;
    margin: 0;
}

.downloadmodal .modal-card {
    width: 610px;
  }

.downloadmodalbtn button {
    width: 242px;
    padding: 27px 0;
    font-size: 16px;
}

.downloadmodal .upl-file-row.upl-file-sec {
    margin-top: 4em;
    margin-bottom: 4em;
}

/*@media screen and (max-width:1190px){
.helpupload .leftHelpBottom {
    left: 6em;
}
.helpupload {
    padding: 10px 6em 0 2.2em;
  }
  .uploadFrm{padding-left: 7em;}
}*/
#Login input {
      align-items: center;
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
}
.save-btn-row{
    margin: 0 auto;
    text-align: center;
}
.save-btn-row .btn{
    width:150px;
}

.save-modl-popup .yellow-btn {
    padding: 6px 10px;
}
.popup-det-alrt h4{
text-align: center;
}
.col-lg-12.Alert-btn {
    /* margin: 0 auto; */
    text-align: center;
}
.popup-det-alrt .Alert-btn .btn {
    min-width: 150px;
    border-radius: inherit;
}

.popup-det-alrt .Alert-btn-setting .btn {
    min-width: 150px;
    border-radius: inherit;
    margin:3px;
}

.Alert-btn {
    margin: 2rem auto 1rem;
}

.Alert-btn .btn-danger{
    margin-right: 1rem;
}
@media screen and (min-width: 769px), print {
  .modal-content,
  .modal-card {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 800px;
  }
}
